import React, { Component } from 'react';
import FadeInSection from '../FadeInSection';
import './Services.css';
import { Checkmark } from 'react-checkmark';

export default class CloudService extends Component {
    render() {
        return (
            <div>
                <FadeInSection>
                    <section className="section1">
                        <img className="imgserv3" height="640px" width="100%" />
                        <div className="imgtxt">
                            <h1 className="imgfnt">Cloud and Platform Services</h1>
                        </div>
                    </section>
                </FadeInSection>
                <br />
                <FadeInSection>
                    <div className="container">
                        <h4 className="htxt">Your Cloud Transformation Partner</h4>
                        <br />
                        <p>
                           In the digital era, cloud is the foundation for agility and growth, allowing you to harness the power 
                             of breakthrough technologies like IoT and artificial intelligence, provide ground-breaking experiences, 
                            and deliver at the pace of innovation. Freed from the need to focus on basic infrastructure and 
                            fueled by next-generation capabilities and tools, you can imagine and act on new ways of doing business, 
                            ahead of the competition with more funds to invest in transformation initiatives.
                        </p>
                        <b><p>
                            Transformation across technology, people, and processes
                        </p></b>
                        <p>
                            From migration to application modernization, cloud-native transformation to API-led integration, 
                            and automation to DevOps and PODS, Thought Circuits can help you maximize the value of a cloud-first way 
                            of working. We will help you transform not just your technology, but your people and processes, too, 
                            so you can realize maximum value from the cloud.
                        </p>
                        <b><p>
                            How cloud can drive value
                        </p></b>
                        <div>
                            <ul>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Make infrastructure invisible</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Deliver apps at the pace of innovation</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Embrace your ideas to reality</span>
                                </li>
                            </ul>
                        </div>
                        <p>
                           When you work with Thought Circuits, you get the benefit of working with the experts in cloud and 
                             application technologies. We bring one of the largest teams of professionally certified digital 
                            and cloud experts and are consistently recognized for our capabilities and vision. We draw on the 
                            depth of our experience and the extent of our expertise to take an outcomes-focused approach to 
                            IT initiatives, mapping business goals to IT objectives.
                        </p>
                    </div>
                </FadeInSection>
                <br />
                <br />
            </div>
        );
    }
}