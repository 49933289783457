import React, { Component } from 'react';
import FadeInSection from '../FadeInSection';

export default class Retail extends Component {
    render() {
        return (
            <div>
                <FadeInSection>
                    <section className="section1">
                        <img className="imgindu4" height="640px" width="100%" />
                        <div className="imgtxt">
                            <h1 className="imgfnt">Consumer and Retail</h1>
                        </div>
                    </section>
                </FadeInSection>
                <div>
                    <FadeInSection>
                        <br />
                        <div className="container">
                            <h5 className="htxt">
                                Consumer preferences for what they want to buy and where and how they want to buy it change fast. 
                                The CPG and retail industries must expand their digital technology solutions to keep up with new 
                                expectations from more demanding customers.
                            </h5>
                            <br />
                            <p>
                                The retail industry is witnessing a paradigm shift. Rapidly evolving technologies, changing 
                                customer expectations, and emerging digital firms are driving widespread disruption. Clearly,
                                from a plethora of new products, markets, and customer segments to the burgeoning expansion of
                                sales and marketing channels (like mobile and social commerce), retail is at a tipping point. 
                                Customers are now demanding rich shopping experiences that are personalized, hyper-connected,
                                and engaging. Additionally, several forces are revolutionizing the landscape: the explosion of
                                data and connected devices, software defined infrastructure, cloud enabled as-a-service and
                                experience, and outcome-driven digital platforms. This makes it imperative for retailers to
                                showcase agility in tech adoption and business process optimization.
                            </p>
                            <p>
                               In fact, 21 CE organizations are recognized by five key tenets: experience-centricity, outcome-based,
                                 agile and lean-focused, service-oriented, and ecosystem-driven.
                            </p>
                            <p>
                                However certain challenges remain. These include lack of business models, siloed applications, 
                                and the inability to deploy new technologies. These impede the delivery of a seamless, omnichannel
                                shopping experience. To overcome these obstacles and future-proof businesses, a robust and 
                                end-to-end strategy is the need of the hour.
                            </p>
                            <p>
                                Thought Circuits leverages the Mode 1-2-3 strategy, focusing a concurrent 3 point spotlight on 
                                existing as well as future growth areas of the business. This helps secure enterprise competitiveness,
                                propelling success in the digital age. Thought Circuits enables retailers to boldly navigate the 
                                new digital age by transitioning from traditional efficiency oriented solutions to business outcome
                                oriented conversations. Our collaborative model helps carve a successful path to enterprise
                                modernization.
                            </p>
                        </div>
                    </FadeInSection>
                </div>
            </div>
        );
    }
}