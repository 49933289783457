import React, { Component } from 'react';
import FadeInSection from '../FadeInSection';
import './Services.css';
import { Checkmark } from 'react-checkmark';

export default class BPService extends Component {
    render() {
        return (
            <div>
                <FadeInSection>
                    <section className="section1">
                        <img className="imgserv2" height="640px" width="100%" />
                        <div className="imgtxt">
                            <h1 className="imgfnt">Business Process Service</h1>
                        </div>
                    </section>
                </FadeInSection>
                <br/>
                <FadeInSection>
                    <div className="container">
                        <h4 className="htxt">Business Process Outsourcing Services</h4>
                        <br />
                        <p>
                            Over the last decade Business Process Outsourcing (BPO) has consistently evolved and refined its value proposition to clients. 
                            At Thought Circuits, we actively focus on BPO services for supporting application lifecycle management in SMB.
                        </p>
                        <b><p>Our services are designed to support a broad range of corporate IT functions:</p></b>
                        <div>
                            <ul>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Requirement Analysis and Management</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Application Development, Maintenance, Modernization and Management</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Establishing and Operating Projects, Program Management of Application Development</span>
                                </li>
                            </ul>
                        </div>
                        <b><p>We deliver effective BPO solutions by:</p></b>
                        <div>
                            <ul>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Employing experienced consultants</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Developing customized processes based on client necessities</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp; Utilizing industry leading tools and products</span>
                                </li>
                            </ul>
                        </div>
                        <p>
                            We shape our engagement approach to the requirements of the client, from developing a BPO candidate list to operating and improving an existing model.
                        </p>
                    </div>
                </FadeInSection>
                <br />
                <br />
            </div>
        );
    }
}