import React, { createRef } from 'react';
import './Careers.css';

import { makeStyles } from '@material-ui/core/styles';
import FadeInSection from '../FadeInSection';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';


export default function Careers() {

    let files = [];
    const fileInput = createRef();

    const processFiles = (event) => {
        files = event.target.files;
       
    }

    const submitResume = (event) => {
        event.preventDefault();
        if (files.length == 0) {
            NotificationManager.warning('Warning', 'Please upload your resume!', 5000);
            return;
        } 
        var formData = new FormData();
        var name = event.target.elements.fullname.value;
        var email = event.target.elements.email.value;
        if (name === '' || email === '') {
            NotificationManager.warning('Warning', 'Please enter all required fields!', 5000);
            return;
        }
        formData.append("fullname", name);
        formData.append("email", email);
        formData.append("message", event.target.elements.message.value);
        formData.append("file",
            files[0], files[0].name);
        var request = new XMLHttpRequest();
        request.open("POST", "/careers/submitresume", true);
        request.send(formData);
        NotificationManager.success('Success', 'Your Profile has been submitted successfully!', 5000);
    }

    const classes1 = makeStyles((theme) => ({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: 600,
            },
        },
    }));

    return (
        <div>
            <FadeInSection>
                <section className="section1">
                    <img className="imgcss3" height="640px" width="100%" />
                    <div className="imgtxt">
                        <h1 className="imgfnt">Create Your Future</h1>
                        <h3>Do what you love</h3>
                    </div>
                </section>
            </FadeInSection>
            <br />
            <FadeInSection>
                <section>
                    <div className="container infosec">
                        <h2><strong>A thriving tech business with endless career opportunities</strong></h2>
                        <br />
                        <div className="row">
                            <div className="col-6">
                                <p>
                                    Inspire to create the future. As industry leaders, the IT systems we build and run are shaping the world and connecting people and technology like never before.
                                </p>
                                <p>
                                    We are committed to developing, retaining and promoting diverse talent like you. So whether you are an exceptionally talented graduate or an
                                </p>
                            </div>
                            <div className="col-6">
                                <p>
                                    experienced professional, you have a career at Thought Circuits.
                                </p>
                                <p>
                                    Come and work with the best people of today to create the best IT of tomorrow.
                                </p>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="container infosec">
                        <h2><strong>Why Thought Circuits ?</strong></h2>
                        <br />
                        <p>
                            Behind every great IT company, there are greater people.
                        </p>
                        <p>
                            At Thought Circuits, we celebrate equality, diversity and achievement. We believe in hiring people that care, with experience, insight and imagination to match.
                        </p>
                        <p>
                            We have always, and will continue to have an appetite for discovery. We've built revolutionary products, services and solutions and now provide a broad range of Thought Circuits services and solutions across America and India.
                        </p>
                        <p>
                            Technology, like your career at Thought Circuits, has endless possibilities.
                        </p>
                    </div>
                </section>
            </FadeInSection>
            <br />
            <br />
            <FadeInSection>
                <section>
                    <div className="container">
                        <h2 className="hc"><strong>Excited to Join us ?</strong></h2>
                        <p>Tell us about you, our experts will contact you ASAP on helping with your next career move.</p>
                        <div>
                            <Card className="card2">
                                <form className={classes1.root} noValidate autoComplete="off" onSubmit={submitResume}>
                                    <TextField
                                        label="Full Name"
                                        id="fullname"
                                        defaultValue=""
                                        variant="outlined"
                                        size="small"
                                        required="true"
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        label="Email"
                                        id="email"
                                        defaultValue=""
                                        variant="outlined"
                                        size="small"
                                        required="true"
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        label="Message"
                                        id="message"
                                        defaultValue=""
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        size="small"
                                    />
                                    <br />
                                    <br />
                                    <label>Resume</label>
                                    <br />
                                    <TextField
                                        type="file"
                                        id="resume"
                                        defaultValue=""
                                        variant="outlined"
                                        size="small"
                                        onChange={processFiles}
                                        ref={fileInput}
                                        multiple={true}
                                    />
                                    <br />
                                    <br />
                                    <button className="btn btn-primary btnclr">Submit</button>
                                    <br />
                                </form>
                            </Card>
                        </div>
                    </div>
                </section>
            </FadeInSection>
            <NotificationContainer />
            <br />
        </div>
    );
}