import React, { Component } from 'react';
import FadeInSection from '../FadeInSection';
import './Services.css';
import { Checkmark } from 'react-checkmark';

export default class SecurityService extends Component {
    render() {
        return (
            <div>
                <FadeInSection>
                    <section className="section1">
                        <img className="imgserv4" height="640px" width="100%" />
                        <div className="imgtxt">
                            <h1 className="imgfnt">Application Security Services</h1>
                        </div>
                    </section>
                </FadeInSection>
                <br />
                <div className="container">
                    <FadeInSection>
                        <h4 className="htxt">Embed security in your digital enterprise and ensure compliance.</h4>
                    </FadeInSection>
                    <br />
                    <FadeInSection>
                        <b><p>Cyber Defense</p></b>
                        <p>Get tailored solutions to support operations across the digital enterprise,
                            while monitoring and responding to the evolving threat landscape.</p>
                        <div>
                            <ul>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                       Enhanced vulnerability management </span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                        Security monitoring</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                        Intelligent threat detection</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                        Faster Incident response</span>
                                </li>
                            </ul>
                        </div>
                    </FadeInSection>
                    <br />
                    <FadeInSection>
                        <b><p>Digital Identity</p></b>
                        <p>Deploy a variety of identity solutions from provisioning and access governance to
                            strong authentication and public key infrastructure.</p>
                        <div>
                            <ul>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                       Regulators and consumers demand better protection of personal data. </span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                        Citizens and customers need trust in digital transactions.</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                        People want effortless authentication experiences to verify their digital identities.</span>
                                </li>
                            </ul>
                        </div>
                    </FadeInSection>
                    <br />
                    <FadeInSection>
                        <b><p>Data Protection</p></b>
                        <p>Protect critical data and meet constantly increasing privacy requirements such as
                            the General Data Protection.</p>
                        <div>
                            <ul>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                       Reduced risk of unintentional disclosure of sensitive data, using Data Protection and Privacy strategies and industry leading solutions. </span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                        Effective Data Protection and Privacy solutions deployed to proven, industry leading best practices by professionally and vendor certified consultants.</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                        Compliance with industry and national regulations and laws.</span>
                                </li>
                            </ul>
                        </div>
                    </FadeInSection>
                    <br />
                    <FadeInSection>
                        <b><p>Secured Infrastructure</p></b>
                        <p>Meet unique security requirements through design, installation and integration of
                            perimeter, network, endpoint and advanced threat protection solutions.</p>
                        <div>
                            <ul>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                       <b>Threat Resistance</b> Your first line of defense provides protection against high volume threats that include malware, spam, phishing, known vulnerabilities and hostile exploits. </span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                        <b>Threat Resilience</b> Your second line of defense prevents a compromise from spreading throughout the organization and empowers you to recover quickly.</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                       <b> Vulnerability Management</b> Protect your enterprise by identifying and managing vulnerabilities through assessments, simulations and mitigation strategies.</span>
                                </li>
                                <li>
                                    <span style={{ display: "inline-flex" }}><Checkmark size={16} color='orange' /> &nbsp;&nbsp;
                                        <b>Digital Enabler</b> &nbsp; Your final line of defense shields your organization with an extra layer of protection to prevent breaches.</span>
                                </li>
                            </ul>
                        </div>
                    </FadeInSection>
                </div>
                <br />
                <br />
            </div>
        );
    }
}