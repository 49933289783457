import React, { Component } from 'react';
import './Footer.css';
import FadeIn from 'react-fade-in';

export default class Footer extends Component {

    render() {
        return (
            <div className="footer">
                <br />
                <FadeIn>
                    <div className="row">
                        <div className="col-3">
                            <ul>
                                <li><a><strong>SERVICES</strong></a></li>
                                <br />
                                <li><a href="/services/appservice">Application Services</a></li>
                                <li><a href="/services/bpservice">Business Process Services</a></li>
                                <li><a href="/services/cloudservice">Cloud and Platform Services</a></li>
                                <li><a href="/services/consulting">Consulting Services</a></li>
                            </ul>
                        </div>
                        <div className="col-3 bar">
                            <ul>
                                <li><a><strong>INDUSTRIES</strong></a></li>
                                <br />
                                <li><a href="/industries/healthcare">Healthcare</a></li>
                                <li><a href="/industries/insurance">Insurance</a></li>
                                <li><a href="/industries/travel">Travel, Transportation and Hospitality</a></li>
                                <li><a href="/industries/banking">Banking and Capital Markets</a></li>
                                <li><a href="/industries/retail">Consumer and Retail</a></li>
                                <li><a href="/industries/utilities">Energy, Utilities, Oil and Gas</a></li>
                                <li><a href="/industries/telecomm">Technology, Media and Telecommunications</a></li>
                            </ul>
                        </div>
                        <div className="col-3 bar">
                            <ul>
                                <li><a><strong>LINKS</strong></a></li>
                                <br />
                                <li><a href="/aboutus">About Us</a></li>
                                <li><a href="/careers">Careers</a></li>
                                <li><a href="/contact">Contact</a></li>
                            </ul>
                        </div>
                        <div className="col-3 bar">
                            <ul>
                                <li><a><strong>CONTACT US</strong></a></li>
                                <br />
                                <li><a href=""> 1300 W.Walnut Hill Lane,  Suite 106</a></li>
                                <li><a href=""> Irving, TX 75038</a></li>
                                <li><a href="">contact@thoughtcircuits.com</a></li>
                            </ul>
                        </div>
                    </div>
                </FadeIn>
                <br />
                <div className="divider"></div>
                <br />
                <footer className="page-footer font-small blue">
                    <div className="footer-copyright text-center py-3">
                        <p text-align="center" style={{ fontSize: "small" }}>All rights reserved @ThoughtCircuits - 2020</p>
                    </div>
                </footer>
            </div>
        );
    }
}